import React from "react";
import { graphql } from "gatsby";
import { StoryblokComponent, useStoryblokState } from "gatsby-source-storyblok";
import Layout from "../Components/Layout";
import { Container } from "../Components/LayoutHelpers";
import styled from "styled-components";
import tw from "twin.macro";
import TabCardGrid from "../Components/TabCardGrid";

const Actions = styled.div`
  ${tw`relative max-w-md text-center mx-auto lg:mx-0`}
  input {
    ${tw`sm:pr-48 pl-8 py-4 sm:py-5 rounded-full border-2 w-full font-medium focus:outline-none transition duration-300  focus:border-primary-500 hover:border-gray-500`}
  }
  button {
    ${tw`w-full sm:absolute right-0 top-0 bottom-0 bg-primary-500 text-gray-100 font-bold mr-2 my-4 sm:my-2 rounded-full py-4 flex items-center justify-center sm:w-40 sm:leading-none focus:outline-none hover:bg-primary-900 transition duration-300`}
  }
`;

const StellenPage = ({ data }) => {
  return (
    <Layout>
      <Container>
        <TabCardGrid />
      </Container>
    </Layout>
  );
};

export default StellenPage;

export const query = graphql`
  query StellenQuery {
    storyblokEntry(full_slug: { eq: "home" }) {
      content
      name
      full_slug
      uuid
      id
      internalId
    }
  }
`;
